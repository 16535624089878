import '../styles/index.scss';
import 'popper.js';
import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/scrollspy';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/tooltip';
import 'bootstrap/js/dist/popover';

import $ from 'jquery';

import 'form-serializer';

import 'sal.js/dist/sal.css';
import sal from 'sal.js';

import 'lazysizes';

import Chart from 'chart.js';

import { CountUp } from 'countup.js';

const startDate = new Date('2020-01-01T00:00:00');

const contactFormUrl = 'https://script.google.com/macros/s/AKfycbxCrfEL2cG-1WXj5FbX6PYp-0pV5NVlZ5oopnEAeSBDJS3-FXw/exec';

const mapStyles = [
  {
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#f5f5f5"
      }
    ]
  },
  {
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#f5f5f5"
      }
    ]
  },
  {
    "featureType": "administrative.land_parcel",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#bdbdbd"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#eeeeee"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#e5e5e5"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#ffffff"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#dadada"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  },
  {
    "featureType": "transit.line",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#e5e5e5"
      }
    ]
  },
  {
    "featureType": "transit.station",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#eeeeee"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#c9c9c9"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  }
];

const initialData = Array(10).fill(0).map((v, i) => {
  const t = new Date(startDate);

  t.setMinutes( startDate.getMinutes() - (9 * 5) + (i * 5));
  return {
    t,
    y: Math.floor(20 - 10 + Math.random() * 20),
  }
});

function initMap() {
  const vlnena = { lat: 48.729, lng: 19.1363327 };
  const map = new google.maps.Map(
    document.getElementById('map'), {
      zoom: 13,
      center: { lat: 48.7514953, lng: 19.1363327 },
      disableDefaultUI: true,
      styles: mapStyles,
    });
  const marker = new google.maps.Marker({ position: vlnena, map: map });
}

window.initMap = initMap;

function handleSubmit() {
  const data = $('#contact-form').serializeObject();
  const $opacityLoader = $('#contact .opacity-loader');
  const $formFeedback = $('#contact .form-feedback');

  $opacityLoader.show();

  $.ajax({
    url: contactFormUrl,
    method: "GET",
    dataType: "json",
    data,
  }).done(() => {
    $opacityLoader.hide();
    $('#contact-form')[0].reset();
    $formFeedback.text('Zpráva odeslána, ozveme se vám.');
    $formFeedback.addClass('text-primary');
  }).fail(() => {
    $opacityLoader.hide();
    $formFeedback.text('Při odesílání zprávy došlo k chybě. Zkuste to ještě jednou nebo nám napište na email.');
    $formFeedback.addClass('text-danger');
  });
}

window.handleSubmit = handleSubmit;

$(function () {
  sal();

  $('#navbarPanel').on('show.bs.collapse', function () {
    $('.hamburger').addClass('is-active');
  });

  $('#navbarPanel').on('hide.bs.collapse', function () {
    $('.hamburger').removeClass('is-active');
  });

  $('#navbarPanel .nav-link').click(function() {
    $('#navbarPanel').collapse('hide');
  });

  const chart = new Chart(document.getElementById('meterChart'), {
    type: 'line',

    data: {
      datasets: [{
        borderColor: '#fff',
        backgroundColor: 'rgba(255,255,255,0)',
        data: initialData,
      }]
    },

    options: {
      legend: {
        display: false,
      },
      tooltips: {
        enabled: false,
      },
      elements: {
        line: {
          tension: 0,
        },
        point: {
          radius: 0,
        },
      },
      scales: {
        xAxes: [{
          type: 'time',
          gridLines: {
            zeroLineColor: '#fff',
            color: "#fff",
            display: true,
            tickMarkLength: 0,
            lineWidth: 1,
          },
          time: {
            displayFormats: {
              minute: 'h:mm',
            },
            unit: 'minute',
            stepSize: 10
          },
          ticks: {
            display: true,
            beginAtZero: false,
            fontColor: '#fff',
            padding: 10,
          }
        }],
        yAxes: [{
          gridLines: {
            drawBorder: false,
            display: false,
          },
          ticks: {
            display: false,
            min: 0,
            max: 35
          }
        }]
      }
    }
  });

  let counter = Math.floor(Math.random() * 100000);

  const MeterGauge = new CountUp('meter__gauge', counter, {
    startVal: counter,
    formattingFn(number) {
      return number.toString().padStart(9, '0');
    }
  });
  MeterGauge.start();

  let i = 1;

  setInterval(function() {
    const date = new Date(startDate);
    date.setMinutes(startDate.getMinutes() + (i * 5));

    const change = 20 - 10 + Math.random() * 20;
    counter += change;

    chart.data.datasets[0].data.push({
      t: date,
      y: change,
    });

    MeterGauge.update(counter);

    if (chart.data.datasets[0].data.length > 10) {
      chart.data.datasets[0].data.shift();
    }
    i++;
    chart.update();
  }, 2000);

  // TODO DEBOUNCE
  $(document).scroll(function () {
    $("#navbar-main").toggleClass('scrolled', $(this).scrollTop() > 0);
  });

  $("#navbarPanel a").on('click', function(event) {
    if (this.hash !== "") {
      // Prevent default anchor click behavior
      event.preventDefault();
      // Store hash
      const hash = this.hash;
      $('html, body').animate({
        scrollTop: $(hash).offset().top
      }, 800, function(){
        // Add hash (#) to URL when done scrolling (default click behavior)
        window.location.hash = hash;
      });
    }
  });

  $('[data-toggle="popover"]').popover();

  $('#contact-form').on('submit', function(e) {
    e.preventDefault();
    grecaptcha.execute();
  })
});
